import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import Sticky from 'react-stickynode';

import { ReactComponent as FacebookIcon } from '~/assets/icons/facebook.svg';
import { ReactComponent as LogoFull } from '~/assets/icons/logo-full.svg';
import { ReactComponent as PhoneIcon } from '~/assets/icons/phone.svg';
import { ReactComponent as ZnanyLekarzIcon } from '~/assets/icons/znanylekarz.svg';
import BurgerButton from '~/components/BurgerButton';
import { CONTACT_PHONE, CONTACT_PHONE_URL, FB_LINK, LINKS, ZNANY_LEKARZ_LINK } from '~/constants';
import routes from '~/routes';

import NavigationLinks from '../NavigationLinks';
import * as styles from './Navigation.module.scss';

interface Props {
  className?: string;
  inverted?: boolean;
}

interface State {
  showMobileMenu: boolean;
  stickyActive: boolean;
}

class Navigation extends React.Component<Props, State> {
  state: State = {
    showMobileMenu: false,
    stickyActive: true,
  };

  handleStickyStateChange = (state: Sticky.Status) => {
    this.setState(() => ({
      stickyActive: state.status === Sticky.STATUS_FIXED,
    }));
  };

  handleItemClick = () => {
    this.setState(() => ({
      showMobileMenu: false,
    }));
  };

  toggleShowMobileMenu = () => {
    this.setState((prevState: State) => ({
      showMobileMenu: !prevState.showMobileMenu,
    }));
  };

  render() {
    const { className, inverted } = this.props;
    const { showMobileMenu, stickyActive } = this.state;

    const navbarClasses = classNames(styles.navbar, className, {
      [styles.inverted]: inverted,
      [styles.open]: showMobileMenu,
    });

    return (
      <header className={styles.root}>
        <Sticky
          enableTransforms={false}
          activeClass={styles.active}
          onStateChange={this.handleStickyStateChange}
          innerZ={3}
        >
          <nav className={navbarClasses} role='navigation'>
            <div className={styles.container}>
              <Link to={routes.ROOT} className={styles.logoLink}>
                <LogoFull className={styles.logo} />
              </Link>

              <BurgerButton
                onClick={this.toggleShowMobileMenu}
                active={showMobileMenu}
                className={styles.burgerButton}
                inverted={inverted || stickyActive}
              />

              <div className={styles.wrapper}>
                <NavigationLinks
                  className={styles.list}
                  itemClassName={styles.item}
                  linkClassName={styles.link}
                  onItemClick={this.handleItemClick}
                  activeClassName={styles.selected}
                  links={LINKS}
                />
              </div>

              <div className={styles.socials}>
                <a href={`tel:${CONTACT_PHONE_URL}`} className={styles.socialLink}>
                  <PhoneIcon className={styles.icon} style={{ marginRight: 4 }} /> {CONTACT_PHONE}
                </a>
                <a href={ZNANY_LEKARZ_LINK} className={styles.socialLink} target='_blank' rel='nofollow noreferrer'>
                  <ZnanyLekarzIcon className={styles.icon} />
                </a>
                <a href={FB_LINK} className={styles.socialLink} target='_blank' rel='nofollow noreferrer'>
                  <FacebookIcon className={styles.icon} />
                </a>
              </div>
            </div>
          </nav>
        </Sticky>
      </header>
    );
  }
}

export default Navigation;
