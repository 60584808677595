import classNames from 'classnames';
import React from 'react';

import * as styles from './BurgerButton.module.scss';

interface Props {
  active?: boolean;
  className?: string;
  onClick?: () => void;
  inverted?: boolean;
}

const BurgerButton: React.FC<Props> = (props) => {
  const classes = classNames(styles.root, props.className, {
    [styles.active]: props.active,
    [styles.inverted]: props.inverted,
  });

  return (
    <button name='Open menu' aria-label='Open menu' type='button' className={classes} onClick={props.onClick}>
      <span className={styles.bar} />
      <span className={styles.bar} />
      <span className={styles.bar} />
    </button>
  );
};

export default BurgerButton;
