import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import Spinner from '~/components/Spinner';

import * as styles from './Button.module.scss';

type StyleProps = {
  isDisabled?: boolean;
  isLoading?: boolean;
};

type a = {
  tag: 'a';
  href: string;
  target?: '_blank';
  rel?: 'noopener noreferrer';
};

type button = {
  tag: 'button';
  type?: 'button' | 'submit' | 'reset';
  name?: string;
};

type link = {
  tag: 'Link';
  to: string;
};

type Props = {
  className?: string;
  as?: a | button | link;
  onClick?: () => void;
} & StyleProps;

const Button: React.FC<Props> = (props) => {
  const classes = classNames(styles.root, props.className, {
    [styles.disabled]: props.isDisabled,
    [styles.loading]: props.isLoading,
  });

  const { as = { tag: 'button' }, onClick, isDisabled, isLoading } = props;

  const ButtonComponent: React.FC = ({ children }) => {
    switch (as.tag) {
      case 'a':
        return (
          <a {...as} className={classes}>
            <span onClick={onClick} role='presentation'>
              {children}
            </span>
          </a>
        );

      case 'button':
        return (
          <button {...as} onClick={onClick} className={classes} disabled={isDisabled || isLoading}>
            {children}
          </button>
        );

      default:
        return (
          <Link {...as} onClick={onClick} className={classes}>
            {children}
          </Link>
        );
    }
  };

  return (
    <ButtonComponent>
      {props.isLoading ? <Spinner className={styles.spinner} size='small' /> : props.children}
    </ButtonComponent>
  );
};

export default Button;
