// extracted by mini-css-extract-plugin
export var root = "Navigation-module--root--35HzM";
export var navbar = "Navigation-module--navbar--1LIZE";
export var open = "Navigation-module--open--2EBZF";
export var container = "Navigation-module--container--18qQT";
export var logo = "Navigation-module--logo--3_1_o";
export var logoLink = "Navigation-module--logoLink--GvC_Z";
export var burgerButton = "Navigation-module--burgerButton--1Epxa";
export var wrapper = "Navigation-module--wrapper--2FQou";
export var list = "Navigation-module--list--e6H4s";
export var item = "Navigation-module--item--1g1iJ";
export var showOnDesktop = "Navigation-module--showOnDesktop--2Fq6e";
export var hideOnDesktop = "Navigation-module--hideOnDesktop--c3E8j";
export var link = "Navigation-module--link--2I_RW";
export var active = "Navigation-module--active--1WOxt";
export var selected = "Navigation-module--selected--1vgVF";
export var inverted = "Navigation-module--inverted--OylgA";
export var socials = "Navigation-module--socials--2Q6R2";
export var socialLink = "Navigation-module--socialLink--25JQj";
export var icon = "Navigation-module--icon--1rC8a";
export var footer = "Navigation-module--footer--3MmTP";