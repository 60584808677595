import 'normalize.css';
import '~/styles/fonts.scss';
import '~/styles/main.scss';

import React from 'react';
import { Helmet } from 'react-helmet';

import { META } from '~/constants';

import Footer from './Footer';
import { renderMetas } from './meta';
import Navigation from './Navigation';

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <Helmet
        key='keywords'
        defaultTitle={META.title}
        titleTemplate={META.titleTemplate}
        titleAttributes={{ itemprop: 'name' }}
      >
        <html lang='pl' />
        {renderMetas()}
      </Helmet>
      <Navigation key='navigation' inverted />

      <main key='content'>{children}</main>

      <Footer key='footer' />
    </>
  );
};

export default Layout;
