import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import { LinkItem } from '~/types';

import * as styles from './NavigationLinks.module.scss';

export interface Props {
  className?: string;
  linkClassName?: string;
  itemClassName?: string;
  activeClassName?: string;
  onItemClick?: () => void;
  links: LinkItem[];
}

interface LinkProps extends Omit<Props, 'className' | 'links'> {
  link: LinkItem;
}

const renderLink = (props: LinkProps) => (
  <li className={classNames(styles.item, props.itemClassName)} key={props.link.route}>
    <Link
      className={classNames(styles.link, props.linkClassName)}
      to={props.link.route}
      title={props.link.label}
      aria-label={props.link.label}
      onClick={props.onItemClick}
      activeClassName={props.activeClassName}
    >
      {props.link.label}
    </Link>
  </li>
);

const NavigationLinks: React.FC<Props> = ({ className, children, links, ...linkProps }) => {
  return (
    <ul className={classNames(styles.list, className)}>
      {links.map((link) =>
        renderLink({
          link,
          ...linkProps,
        }),
      )}
      {children}
    </ul>
  );
};

export default NavigationLinks;
