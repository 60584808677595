import React from 'react';

import { META, WEBSITE_URL } from '~/constants';

export const renderMetas = () => [
  <meta key='charset' charSet='utf-8' />,
  <meta key='viewport' name='viewport' content='width=device-width, initial-scale=1, viewport-fit=cover' />,
  <meta key='description' name='description' content={META.description} />,
  <meta key='keywords' name='keywords' content={META.keywords} />,
  <meta key='name' itemProp='name' content={META.title} />,
  <meta key='description' itemProp='description' content={META.description} />,
  <meta key='image' itemProp='image' content={`${WEBSITE_URL}/og-cover.png`} />,
  <meta key='og:title' property='og:title' content={META.title} />,
  <meta key='twitter:card' name='twitter:card' content='summary_large_image' />,
  <meta key='twitter:title' name='twitter:title' content={META.title} />,
  <meta key='og:description' property='og:description' content={META.description} />,
  <meta key='og:type' property='og:type' content='website' />,
  <meta key='og:image' property='og:image' content={`${WEBSITE_URL}/og-cover.png`} />,
  <meta key='og:site_name' property='og:site_name' content={META.title} />,
  <meta
    key='apple-mobile-web-app-status-bar-style'
    name='apple-mobile-web-app-status-bar-style'
    content='black-translucent'
  />,
  <link key='manifest' rel='manifest' href='/manifest.webmanifest' />,
  <link key='mask-icon' rel='mask-icon' href='/safari-pinned-tab.svg' color='#4c4c4c' />,
  <meta key='theme-color' name='theme-color' content='#f5f5f5' />,
];
