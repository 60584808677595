// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-cennik-index-tsx": () => import("./../../../src/pages/cennik/index.tsx" /* webpackChunkName: "component---src-pages-cennik-index-tsx" */),
  "component---src-pages-galeria-index-tsx": () => import("./../../../src/pages/galeria/index.tsx" /* webpackChunkName: "component---src-pages-galeria-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-kontakt-index-tsx": () => import("./../../../src/pages/kontakt/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-index-tsx" */),
  "component---src-pages-o-nas-index-tsx": () => import("./../../../src/pages/o-nas/index.tsx" /* webpackChunkName: "component---src-pages-o-nas-index-tsx" */),
  "component---src-pages-oferta-index-tsx": () => import("./../../../src/pages/oferta/index.tsx" /* webpackChunkName: "component---src-pages-oferta-index-tsx" */),
  "component---src-pages-zespol-index-tsx": () => import("./../../../src/pages/zespol/index.tsx" /* webpackChunkName: "component---src-pages-zespol-index-tsx" */)
}

