import React from 'react';

import { ReactComponent as Waves } from '~/assets/background/waves.svg';
import Button from '~/components/Button';
import { CONTACT_EMAIL, CONTACT_PHONE, CONTACT_PHONE_URL } from '~/constants';

import * as styles from './Footer.module.scss';

type Props = {
  inverted?: boolean;
};

const CURRENT_YEAR = new Date().getFullYear();

const Footer: React.FC<Props> = () => (
  <footer role='contentinfo' className={styles.rootContainer}>
    <Waves className={styles.waves} />

    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.header}>Adres gabinetu</div>
          <p className={styles.text}>
            Gabinet Stomatologiczny Anna Stós
            <br />
            Nowy Świat 25/14
            <br />
            33-100 Tarnów
            <br />
            <Button
              as={{
                tag: 'a',
                href: 'https://www.google.pl/maps/dir//Nowy+%C5%9Awiat+25,+33-100+Tarn%C3%B3w/@50.0143224,20.9828628,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x473d8356204b476b:0x1e4bfa17e644ce5a!2m2!1d20.9828628!2d50.0143224',
              }}
            >
              Kieruj mnie
            </Button>
          </p>
        </div>
        <div className={styles.column}>
          <div className={styles.header}>Godziny otwarcia</div>
          <p className={styles.text}>
            Pn. 13:00 - 20:00
            <br />
            Wt. 13:00 - 20:00
            <br />
            Śr. 13:00 - 20:00
            <br />
            Czw. 7:00 - 15:00
            <br />
            Czwartki to dni profilaktyki i higienizacji.
            <br />
            Pt. 10:00 - 14:00
            <br />
          </p>
        </div>
        <div className={styles.column}>
          <div className={styles.header}>Kontakt</div>
          <p className={styles.text}>
            Mailowo:
            <br />
            <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
            <br />
            <br />
            Telefonicznie:
            <br />
            <a href={`tel:${CONTACT_PHONE_URL}`}>{CONTACT_PHONE}</a>
          </p>
        </div>
      </div>

      <small className={styles.disclaimer}>
        Wszelkie prawa zastrzeżone (c) {CURRENT_YEAR} Anna Stós Gabinet Stomatologiczny
      </small>
    </div>
  </footer>
);

export default Footer;
