import { oneLine } from 'common-tags';

import routes from '~/routes';

import { LinkItem } from './types';

export const WEBSITE_URL = process.env.WEBSITE_URL as string;

export const CONTACT_EMAIL = 'zyjo12@poczta.onet.pl';
export const CONTACT_PHONE = '+48 602 179 821';
export const CONTACT_PHONE_URL = '+48602179821';
export const FB_LINK = 'https://www.facebook.com/stomatolog.anna.stos';
export const ZNANY_LEKARZ_LINK = 'https://www.znanylekarz.pl/anna-stos/stomatolog/tarnow';

export const META = {
  keywords: [
    'stomatolog',
    'dentysta',
    'leczenie',
    'zęby',
    'stomatologia',
    'protetyka',
    'endodoncja',
    'profilaktyka',
    'chirurgia',
    'choroby przezębia',
  ].join(', '),
  description: oneLine`
    Profesjonalne konsultacje i zabiegi stomatologiczne.
    Gabinet przyjazny dziecią. Nowoczesny i niezawodny sprzęt.
  `,
  title: 'Anna Stós – Stomatolog Tarnów',
  titleTemplate: '%s | Anna Stós – Stomatolog Tarnów',
  imgAlt: 'Anna Stós – Stomatolog Tarnów',
};

export const NAVIGATION_HEIGHT = 80;

export const Breakpoint = {
  EXTRA_SMALL: 480,
  SMALL: 768,
  MEDIUM: 992,
  LARGE: 1200,
  EXTRA_LARGE: 1480,
};

export const YOUTUBE_VIDEO_ALLOW_OPTIONS: string[] = [
  'accelerometer',
  'autoplay',
  'encrypted-media',
  'gyroscope',
  'picture-in-picture',
];

export const ROOT_NODE = '___gatsby';

export const LINKS: LinkItem[] = [
  {
    label: 'O nas',
    route: routes.ABOUT_US,
  },
  {
    label: 'Zespół',
    route: routes.TEAM,
  },
  {
    label: 'Oferta',
    route: routes.SERVICES,
  },
  {
    label: 'Cennik',
    route: routes.PRICES,
  },
  {
    label: 'Galeria',
    route: routes.GALLERY,
  },
  {
    label: 'Kontakt',
    route: routes.CONTACT,
  },
];

// export const POLICY_LINKS: LinkItem[] = [
//   {
//     label: 'Polityka Cookie',
//     route: routes.COOKIES_POLICY,
//   },
//   {
//     label: 'Polityka Prywatności',
//     route: routes.PRIVACY_POLICY,
//   },
// ];
