// Make sure each route has an ending slash, otherwise links will not receive active css class
// More info https://github.com/gatsbyjs/gatsby/issues/10586
const routes = {
  ROOT: '/',
  // COOKIES_POLICY: '/#polityka-cookie/',
  // PRIVACY_POLICY: '/#polityka-prywatnosci/',
  PRICES: '/cennik/',
  SERVICES: `/oferta/`,
  TEAM: `/zespol/`,
  ABOUT_US: `/o-nas/`,
  GALLERY: '/galeria/',
  CONTACT: `/kontakt/`,
};

export default routes;
